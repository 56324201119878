// type
import { Box, Text, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Box,
  typography: Text,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;
let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
  }
  
  const permissions = JSON.parse(decryptedData)?.permissions;

const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission?.includes(brand));
}

// ==============================|| MENU ITEMS - productz ||============================== //

const productz = permissionsAccessFunction('product') ?  {
  id: 'utilities',
  title: 'Products',
  icon: icons.navigation,
  type: 'group',
  children: [
   
    allowedPermissions?.includes('get-product') ? {
      id: 'all-products',
      title: 'All Products',
      type: 'item',
      url: '/products/all-products',
      icon: icons.navigation,
      breadcrumbs: true
    } : null,

    allowedPermissions?.includes('register-product') ? {
      id: 'create-products',
      title: 'Add Product',
      type: 'item',
      url: '/products/create-product',
      icon: icons.navigation,
      breadcrumbs: true
    } : null,

  ].filter(Boolean)
} : null;

export default productz;
