import { VIEW_REPORT_PROBLEM , EXPORT_CSV_REPORT_PROBLEM} from "../constant/ActionType";

export default function ReportProblemReducer(
  state = {
    ReportProblem: [],
    perPage: 10,
    lastPage: 1,
    csvReportProblem:[],
  },
  action
) {
  switch (action.type) {
    case VIEW_REPORT_PROBLEM:
      return {
        ...state,
        ReportProblem: action.data?.data,
        perPage: action.data?.data?.per_page,
        lastPage: action.data?.data?.last_page
      };
    case EXPORT_CSV_REPORT_PROBLEM:

      return {
        ...state,
        csvReportProblem: action.data,
      };


    default:
  }

  return state;
}
