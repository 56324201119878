import {
    REGISTER_BRAND_USER
} from "../constant/ActionType";

export default function registerBrandUser(state = {
    registerbranduser: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case REGISTER_BRAND_USER:
            return {
                ...state,
                registerbranduser: action.data,
                    statuscode: action.status
            };

        default:
    }

    return state;
}