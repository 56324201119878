// material-ui

import MainCard from "components/MainCard";

import { onPageChange } from "helper/paginationHelper";
import GetColumnSearchProps from "helper/searchHelper";
import Chip from "@mui/material/Chip";
import { format } from "date-fns";
import { Category2 } from "iconsax-react";
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";
import EcommerceDataChart from "sections/dashboard/EcommerceDataChart";
import { useTheme } from "@mui/material/styles";
import { CSVLink } from "react-csv";
import AnimateButton from "components/@extended/AnimateButton";
import ThemeInput from "components/inputs/ThemeInput";
import { AES, enc } from "crypto-js";
import * as url from "../../store/constant/Endpoints";
import { Typography } from "@mui/material";

import {
  Table,
  //  Popconfirm,
  Pagination,
  Spin,
} from "antd";

import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  GetAllQrcodeReport,
  FetchExportQRCSV,
  AllCompanies,
  AllBatches,
  FetchAllProductz,
  FetchMapingData,
} from "store/action/index";
import { dispatch } from "store/index";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AllQRcodeReports = ({
  QrcodeReport,
  lastPage,
  csvQRCode,
  allcompany,
  viewAllProducts,
  allbatches,
  mapalldata,
}) => {
  const theme = useTheme();
  const [ExportcsvData, setExportCsv] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [totalPageView, setTotalPageView] = useState(10);
  const [searchProduct, setsearchProduct] = useState("");
  const [searchBatch, setsearchBatch] = useState("");
  const [searchCompany, setsearchCompany] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredBatch, setFilteredBatch] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uopdate, setUpdate] = useState("");
  const [from_date, setDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [params, setparams] = useState({
    page: 1,
    from_date: from_date,
    to_date: to_date,
    company_id: searchCompany,
    product_id: searchProduct,
    batch_id: searchBatch,
  });

  let permissionUser = localStorage.getItem("data");

  let decryptedData = null;
  if (permissionUser) {
    try {
      // Code that may throw an exception
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
      // ...
    } catch (error) {
      console.log(error, "er");
    }
  }

  const permissions = JSON.parse(decryptedData)?.permissions;
  const allowedPermissions =
    permissions && permissions.length !== 0
      ? permissions.map((permission) => permission?.permission)
      : [];

  const permissionsAccessFunction = (brand) => {
    return (
      allowedPermissions &&
      allowedPermissions.length !== 0 &&
      allowedPermissions.some((permission) => permission.includes(brand))
    );
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%", // Stretch to the screen width
  };

  const tableStyle = {
    flex: 1, // Make the table stretch to fill available space
    overflowX: "auto",
    marginTop: 5,
  };

  const mergeLatitudeAndLongitude = (latitude, longitude) => {
    if (latitude && longitude) {
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    } else {
      return "-";
    }
  };

  useEffect(() => {
    setparams({
      ...params,
      from_date,
      to_date,
      to_date: to_date,
      company_id: searchCompany,
      product_id: searchProduct,
      batch_id: searchBatch,
    });
  }, [uopdate]);

  useEffect(() => {
    dispatch(AllCompanies());
    dispatch(FetchAllProductz());
    dispatch(AllBatches());
    dispatch(FetchMapingData());
  }, []);

  const onShowSizeChange = (current, pageSize) => {
    setTotalPageView(pageSize);
  };
  useEffect(() => {
    dispatch(FetchExportQRCSV(params));
    const param = { ...params, limit: totalPageView ?? 10 };
    setLoading(true);

    const timeoutId = setTimeout(() => {
      dispatch(GetAllQrcodeReport({ params: param, setLoading: setLoading }));
    }, 2000);

    // Clear the timeout if the component unmounts or if params change again
    return () => {
      clearTimeout(timeoutId);
    };
  }, [params]);

  const ResetFilter = () => {
    setLoading(true);
    setsearchCompany("");
    setsearchProduct("");
    setsearchBatch("");
    dispatch(GetAllQrcodeReport({ setLoading: setLoading }));
  };

  useEffect(() => {
    if (csvQRCode?.data?.logs) {
      const newArray = csvQRCode?.data?.logs?.map(
        ({
          id,
          batch_id,
          product_id,
          company_id,
          brand_id,
          updated_at,
          status,
          random_number_id,
          ...rest
        }) => rest
      );

      setExportCsv(newArray);
    }
    console.log("reportss", csvQRCode?.data?.logs);
  }, [csvQRCode?.data?.logs]);

  const handleSubmit = () => {
    dispatch(
      GetAllQrcodeReport({
        params: {
          ...params,
          company_id: searchCompany,
          product_id: searchProduct,
          batch_id: searchBatch,
          to_date: to_date,
          from_date: from_date,
        },
        setLoading: setLoading,
      })
    );
  };

  useEffect(() => {
    if (searchCompany) {
      const filtered =
        viewAllProducts &&
        viewAllProducts.length !== 0 &&
        viewAllProducts.filter((item) => item.company_id == searchCompany);
      setFilteredProducts(filtered);
      const filterBatch =
        allbatches &&
        allbatches.length !== 0 &&
        allbatches.filter((item) => item.company_id == searchCompany);
      setFilteredBatch(filterBatch);
    } else {
      setFilteredProducts(viewAllProducts);
      if (allbatches) {
        setFilteredBatch(allbatches);
      }
    }
  }, [searchCompany, allbatches]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!csvQRCode.data?.logs) {
        setButtonDisabled(true);
      }
    }, 10000); // 10 seconds in milliseconds

    return () => clearTimeout(timeout);
  }, [csvQRCode.data?.logs]);

  const columns = [
    {
      title: "Random Number",
      dataIndex: "random_number",
      key: "number",
      ...GetColumnSearchProps("number", params, setparams),
    },
    {
      title: "Response",
      dataIndex: "response",
      key: "response",
      ...GetColumnSearchProps("response", params, setparams),
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Product",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Batch",
      dataIndex: "batch_name",
      key: "batch_name",
    },

    // allowedPermissions.includes('map_location') ? {
    //   title: 'Location',
    //   key: 'location',
    //   render: (record) => {
    //     const latitude = record.Latitude;
    //     const longitude = record.Longitude;
    //     const location = mergeLatitudeAndLongitude(latitude, longitude);
    //     return (
    //       <a
    //         href={location === '-' ? null : location}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         {location}
    //       </a>
    //     );
    //   },
    // }:null,
    //     {
    //       title: ' Status',
    //       dataIndex: 'status',
    //       key: 'status',
    //       render: (status) => {

    //         return(

    // <span>

    //   {

    //   status === 1 ? (

    //     <Chip label="Verified" color="success" style={{ borderRadius: 8 }} />
    //   ) : status === 2 ? (

    //     <Chip label="Duplicate" color="secondary" style={{ borderRadius: 8 }} />
    //   ) : status === 5 ? (

    //     <Chip label="Fake" color="error" style={{ borderRadius: 8 }} />

    //  ) :status === 3 ?(

    //   <Chip label="Deactivated/Blocked" color="error" style={{ borderRadius: 8 }} />

    //  ):(

    // null

    //  )

    // }
    // </span>
    //       )}
    //     },

    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const date = new Date(created_at);
        const formattedDate = format(date, "dd/MM/yyyy");
        return <span>{formattedDate}</span>;
      },
    },
  ];

  if (allowedPermissions.includes("map_location")) {
    columns.push({
      title: "Location",
      key: "location",
      render: (record) => {
        const latitude = record.Latitude;
        const longitude = record.Longitude;
        const location = mergeLatitudeAndLongitude(latitude, longitude);
        return (
          <a
            href={location === "-" ? null : location}
            target="_blank"
            rel="noopener noreferrer"
          >
            {location}
          </a>
        );
      },
    });
  }

  columns.push({
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      return (
        <span>
          {status === 1 ? (
            <p style={{ color: "green" }}>V</p>
          ) : status === 2 ? (
            <p style={{ color: "blue" }}>D</p>
          ) : status === 4 ? (
            <p style={{ color: "blue" }}>D</p>
          ) : status === 5 ? (
            <p style={{ color: "red" }}>F</p>
          ) : status === 3 ? (
            <p style={{ color: "red" }}>B/D</p>
          ) : null}
        </span>
      );
    },
  });

  useEffect(() => {
    console.log("counntntntns", QrcodeReport);
  }, [QrcodeReport]);

  const HandleChange = (page) => {
    onPageChange(setparams, params, page);
    setUpdate(page);
    console.log("check state", searchCompany);
  };

  return (
    <Grid container rowSpacing={4.5}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "40px",
        }}
      >
        <Grid item xs={12} sm={6} lg={2} className="px-2">
          <EcommerceDataCard
            title="Total Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {QrcodeReport?.totalCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={2} className="px-2">
          <EcommerceDataCard
            title="Correct Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {QrcodeReport?.CorrectCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={12} sm={6} lg={2} className="px-2">
          <EcommerceDataCard
            title="Fake Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {QrcodeReport?.WrongCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={2} className="px-2">
          <EcommerceDataCard
            title="Blocked Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {QrcodeReport?.BlockedCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>

        <Grid item xs={12} sm={6} lg={2} className="px-2">
          <EcommerceDataCard
            title="Duplicate Counts"
            count={
              <>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {QrcodeReport?.DuplicateCount ?? 0}
                </Typography>
              </>
            }
            color={theme.palette.dark}
            iconPrimary={
              <Category2 color={theme.palette.dark} variant="TwoTone" />
            }
          >
            <EcommerceDataChart color="sicpa.light" count={0} />
          </EcommerceDataCard>
        </Grid>
      </div>

      <div className="container-fluid" style={{ marginTop: "20px" }}>
        <div className="row">
          <div className="col-md-4 my-2">
            <Stack spacing={1}>
              <InputLabel htmlFor="company">Search Company</InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={searchCompany}
                  onChange={(e) => setsearchCompany(e.target.value)}
                  id="company"
                  name="roleid"
                  displayEmpty
                  input={<OutlinedInput />}
                >
                  <MenuItem disabled value="">
                    <em>Search Company</em>
                  </MenuItem>

                  <MenuItem value="">None</MenuItem>
                  {allcompany &&
                    allcompany.length !== 0 &&
                    allcompany.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e.id}>
                          {e?.company_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          </div>
          <div className="col-md-4 my-2">
            <Stack spacing={1}>
              <InputLabel htmlFor="company">Search Product*</InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={searchProduct}
                  onChange={(e) => setsearchProduct(e.target.value)}
                  id="company"
                  name="roleid"
                  displayEmpty
                  input={<OutlinedInput />}
                >
                  <MenuItem disabled value="">
                    <em>Search Product</em>
                  </MenuItem>

                  <MenuItem value="">None</MenuItem>
                  {filteredProducts &&
                    filteredProducts.length !== 0 &&
                    filteredProducts.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          </div>
          <div className="col-md-4 my-2">
            <Stack spacing={1}>
              <InputLabel htmlFor="company">Search Batch*</InputLabel>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={searchBatch}
                  onChange={(e) => setsearchBatch(e.target.value)}
                  id="company"
                  name="roleid"
                  displayEmpty
                  input={<OutlinedInput />}
                >
                  <MenuItem disabled value="">
                    <em>Search Batch</em>
                  </MenuItem>
                  <MenuItem value="">None</MenuItem>

                  {filteredBatch &&
                    filteredBatch.length !== 0 &&
                    filteredBatch.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e.id}>
                          {e.batch_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          </div>
          <div className="col-md-4">
            <ThemeInput
              onChange={(e) => setDate(e.target.value)}
              type="date"
              name="Form Date"
              value={from_date}
            />
          </div>
          <div className="col-md-4">
            <ThemeInput
              onChange={(e) => setToDate(e.target.value)}
              type="date"
              name="To Date"
              value={to_date}
            />
          </div>
        </div>
      </div>

      <Grid item xs={12}>
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
              }}
            >
              <div style={{ marginRight: "17px" }}>
                <AnimateButton>
                  <Button
                    onClick={ResetFilter}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Reset
                  </Button>
                </AnimateButton>
              </div>

              <AnimateButton>
                <Button
                  onClick={handleSubmit}
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </AnimateButton>

              <CSVLink
                filename={"ExportSMSReport.csv"}
                data={ExportcsvData ?? []}
                className="css-h4we56-MuiButtonBase-root-MuiButton-root"
                style={{
                  background: "#58D62A",
                  marginLeft: "10px",
                  width: "134px",
                  fontSize: "13px",
                }}
                disabled={isButtonDisabled}
              >
                {csvQRCode?.data?.logs ? " Export to CSV" : "Loading.."}
              </CSVLink>
            </div>
          </div>
        </div>
      </Grid>

      <MainCard sx={tableStyle}>
        <div style={{ textAlign: "start" }}>
          <Spin spinning={loading}>
            <p
              sx={{
                color: "green",
                marginTop: "10px",
                fontSize: "30px",
                textAlign: "start",
              }}
            >
              <span style={{ fontSize: "27px" }}> v =</span>
              <Chip
                label="Verified"
                style={{
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: 8,
                }}
              />
              , <span style={{ color: "red", fontSize: "22px" }}> F =</span>
              <Chip label="Fake" color="error" style={{ borderRadius: 8 }} />,
              <span style={{ color: "blue", fontSize: "22px" }}> D =</span>
              <Chip
                label="Duplicate"
                color="secondary"
                style={{ borderRadius: 8 }}
              />
              , <span style={{ color: "red", fontSize: "22px" }}>B/D =</span>
              <Chip
                label="Block/Duplicate"
                color="error"
                style={{ borderRadius: 8 }}
              />
            </p>
            <div style={{ overflowX: "auto" }}>
              <Table
                className="border rounded"
                style={{ marginTop: 25, overflowX: "auto" }}
                dataSource={QrcodeReport?.logs?.data}
                columns={columns}
                pagination={false}
                rowKey="id"
                components={{
                  body: {
                    cell: ({ style, ...restProps }) => {
                      return (
                        <td
                          style={{
                            ...style,
                            padding: "1px",
                            textAlign: "center",
                          }}
                          {...restProps}
                        />
                      );
                    },
                  },
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <Pagination
                  total={Number(lastPage ? lastPage : 1) * 10}
                  defaultCurrent={params.page}
                  defaultPageSize={totalPageView}
                  onShowSizeChange={onShowSizeChange}
                  onChange={(page) => HandleChange(page)}
                />
              </div>
            </div>
          </Spin>
        </div>
      </MainCard>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    QrcodeReport: state?.Reports?.ViewQrcodeReport,
    lastPage: state?.Reports?.lastPageQR,
    csvQRCode: state?.CSvQRReducer?.csvQRCode,
    allbatches: state?.AllBatchesReducers?.allbatches,
    mapalldata: state?.MapDataReducers?.mapalldata,
    allcompany: state?.AllCompaniesReducers?.allcompany,
    viewAllProducts: state?.AllProducts?.ViewProductz,
  };
};

export default connect(mapStateToProps)(AllQRcodeReports);
