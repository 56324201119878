// type
import { Home3, Tag2, Colorfilter, Barcode } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

// icons
const icons = {
  navigation: Home3,
  typography: Tag2,
  color: Colorfilter,
  shadow: Barcode,
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = JSON.parse(decryptedData)?.permissions;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions?.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions?.some((permission) => permission?.includes(brand))
  );
};

// ==============================|| MENU ITEMS - brand ||============================== //

const reports = permissionsAccessFunction("report")
  ? {
      id: "utilities",
      title: "Reports",
      icon: icons.Tag2,
      type: "group",
      children: [
        allowedPermissions?.includes("sms-report")
          ? {
              id: "sms-reports",
              title: "All SMS Reports",
              type: "item",
              url: "/reports/all-sms-reports",
              icon: Tag2,
              breadcrumbs: true,
            }
          : null,

        // {
        //   id: "sms-QR-reports",
        //   title: "Scan Qr Locationss",
        //   type: "item",
        //   url: "/reports/scan-qr-location",
        //   icon: Tag2,
        //   breadcrumbs: true,
        // },
        allowedPermissions?.includes("qr-report")
          ? {
              id: "qrcode-reports",
              title: "All QRcode Reports",
              type: "item",
              url: "/reports/all-qrcode-reports",
              icon: Tag2,
              breadcrumbs: true,
            }
          : null,

        allowedPermissions?.includes("problem-report")
          ? {
              id: "problem-report",
              title: "All Reports Problem",
              type: "item",
              url: "/reports/reports-problem",
              icon: Tag2,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;

export default reports;
