// type
import { Barcode, Text, Colorfilter } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";

// icons
const icons = {
  navigation: Barcode,
  typography: Text,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
  }
  
  const permissions = JSON.parse(decryptedData)?.permissions;


const allowedPermissions = permissions && permissions.length !== 0 ? permissions?.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions?.some(permission => permission?.includes(brand));
}

// ==============================|| MENU ITEMS - productz ||============================== //

const randomcode = permissionsAccessFunction('randomcodes') || permissionsAccessFunction('update-ratelimit') ? {
  id: 'utilities',
  title: 'Random Code',
  icon: icons.navigation,
  type: 'group',
  children: [
   
    allowedPermissions?.includes('get-randomcodes') ? {
      id: 'all-random-code',
      title: 'View Random Codes',
      type: 'item',
      url: '/random/all-random-code',
      icon: icons.navigation,
      breadcrumbs: true
    } : null,
    allowedPermissions?.includes('create-randomcodes') ? {
      id: 'create-random-code',
        title: 'Add Random Codes',
        type: 'item',
        url: '/random/create-random-code',
        icon: icons.navigation,
        breadcrumbs: true
      } : null


  ].filter(Boolean)
} : null;

export default randomcode;
