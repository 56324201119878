// material-ui

import MainCard from 'components/MainCard';
// import PasswordInput from 'components/inputs/PasswordInput';
import ThemeInput from 'components/inputs/ThemeInput';
import { useEffect, useState } from 'react';
import { Grid, InputLabel, Select, MenuItem, FormControl, Stack, OutlinedInput, Button } from '@mui/material';
import { Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  toast
} from 'react-toastify';
import { dispatch } from 'store/index';
import { AllSubCategoryGet,  FetchAllActiveCompanies, FetchAllCategeory, HandleEditProductz, RegisterProductz } from 'store/action/index';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const RegisterProduct = ({  allCategory, allSubCategory, allCompanies }) => {
    const location = useLocation()
  const [name, setName] = useState(location.state !== null ? location.state?.name : "");
  const [description, setDescription] = useState(location.state !== null ? location.state?.description : "");
//   const [email, setEmail] = useState("");

  const [cate_id, setCate_id] = useState(location.state !== null ? location.state?.cate_id : "");
  const [sub_cat_id, setSub_cat_id] = useState(location.state !== null ? location.state?.sub_cat_id : "");
  const [company_id, setCompany_id] = useState(location.state !== null ? location.state?.company_id : "");
  const [image, setImage] = useState("");



  const [filteredCategory, setFilteredCategory] = useState([]);
  const [filteredSubCategory, setFilteredSubCategory] = useState([]);


//   const [userobj, setUserObj] = useState({});
//   const navigate = useNavigate()

  let userId = localStorage.getItem('Id');
  useEffect(() => {

    dispatch(FetchAllCategeory())
    dispatch(AllSubCategoryGet())
    dispatch(FetchAllActiveCompanies())

  }, [])

  useEffect(() => {
    
    if (company_id) {
      const filteredCat = allCategory && allCategory.length !== 0 && allCategory.filter(item => item.company_id === company_id);
      setFilteredCategory(filteredCat);
    } else {
      setFilteredCategory(allCategory);
    }
  }, [company_id, allCompanies]);

  useEffect(() => {
    if (company_id && cate_id) {
      const filteredSubCategories = allSubCategory.filter(
        (item) => item.company_id === company_id && item.catId === cate_id
      );
      setFilteredSubCategory(filteredSubCategories);
    } else {
      setFilteredSubCategory(allSubCategory);
    }
  }, [company_id, cate_id, allCompanies, allCategory]);

  const handleImageUpload = async (file, onSuccess, onError) => {
    try {
      setImage(file)
      onSuccess();
    } catch (error) {
      onError(error);
    }
  }

  const handleSubmit = () => {
    if (
      name !== "" &&
      description !== "" &&
      userId !== "" &&
      cate_id !== "" &&
      // sub_cat_id !== "" &&
      company_id !== ""
    ) {

        location.state !== null ?
        dispatch(HandleEditProductz({
            id:location.state.id,
            name: name,
          description: description,
          image: image,
          cate_id: cate_id,
          // sub_cat_id: sub_cat_id,
          company_id: company_id
        }))
        :
      dispatch(RegisterProductz({
          name: name,
        description: description,
        created_by: userId,
        image: image,
        cate_id: cate_id,
        // sub_cat_id: sub_cat_id,
        company_id: company_id
      }))
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: 'toastStyle',
      });
    }

  }


//   const EditApiData = () => {
//     dispatch(HandleEditUserApi(userobj))
//     navigate('/user-management/users');

//   }

  return (
    // <Grid container rowSpacing={4.5} columnSpacing={2.75}>
   
          <MainCard>
            <Spin spinning={allCategory && allCategory.length !== 0 && allSubCategory && allSubCategory.length !== 0 && allCompanies && allCompanies.length !== 0 ? false : true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className='row'>
                    <div className='col-md-4 my-2'>
                      <ThemeInput id="name" value={name} onChange={(e) => setName(e.target.value)} type="text" name="Name*" placeholder="Enter Name" />
                    </div>
                    
       

                    <div className='col-md-4 my-2'>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="company">Company*</InputLabel>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              value={company_id}
                              onChange={(e) => setCompany_id(e.target.value)}
                              id="company"
                              name="roleid"
                              displayEmpty
                              input={<OutlinedInput />}          >
                              {/* You can add your select options here */}
                              <MenuItem disabled value="">
                                <em>Select Company</em>
                              </MenuItem>

                              {allCompanies && allCompanies.length !== 0 && allCompanies.map((e, i) => {
                                return <MenuItem key={i} value={e.id}>{e.company_name}</MenuItem>
                              })}

                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>

                    </div>
                  

                  <div className='col-md-4 my-2'>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="category">Category*</InputLabel>
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              value={cate_id}
                              disabled={company_id === ""}
                              onChange={(e) => setCate_id(e.target.value)}
                              id="category"
                              name="roleid"
                              displayEmpty
                              input={<OutlinedInput />}          >

                              <MenuItem disabled value="">
                                <em>Select Category</em>
                              </MenuItem>

                              {filteredCategory && filteredCategory.length !== 0 && filteredCategory.map((e, i) => {
                                return <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                              })}

                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>

                    </div>
                    
             
              
                    <div className='col-md-4 my-2'>
                      <ThemeInput id="description" value={description} onChange={(e) => setDescription(e.target.value)} type="text" name="Description*" placeholder="Enter Description" />
                    </div>

                    <div className='col-md-4 my-2'>
                      <InputLabel htmlFor="image-option">Image*</InputLabel>

                      <Upload
                        customRequest={({ file, onSuccess, onError }) =>
                          handleImageUpload(file, onSuccess, onError)
                        }
                        id={`image-option`}
                        listType="picture"
                        maxCount={1}
                        showUploadList={true}
                      >
                        <Button fullWidth
                          className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                        >
                          <UploadOutlined style={{ marginRight: 10 }} /> Upload (Max: 1)
                        </Button>
                      </Upload>
                    </div>

                  </div>

                  <div className='row my-3' style={{textAlign:"end", display:"flex", justifyContent:"flex-end", marginRight:5}}>
                    
                        <Button
                        style={{width:"auto"}}
                        className="px-3"
                          onClick={handleSubmit}
                          disableElevation
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                         {location.state !== null ? "Update" : "Add New"} Product
                        </Button>
                    </div>                </Grid>
              </Grid>

            </Spin>
          </MainCard>
       
    //  </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    allCategory: state?.AllGetCateeReducers.allcategoeries,
    allSubCategory: state?.SubAllCateReducers.getallsubcategoery,
    allCompanies: state?.AllActiveCompanies?.AllActiveCompanies
  };
};


export default connect(mapStateToProps)(RegisterProduct);