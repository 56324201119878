
import {
    ALL_SUB_CATEGORIES
} from "../constant/ActionType";

export default function SubAllCateReducers(state = {
    getallsubcategoery: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_SUB_CATEGORIES:
            return {
                ...state,
                getallsubcategoery: action?.data?.data?.data,
            };

        default:
    }

    return state;
}