import {
    PROGRESSIVE_CODES
} from "../constant/ActionType";

export default function ProgressReducer(state = {
    progress: [],

}, action) {
    switch (action.type) {
        case PROGRESSIVE_CODES:
            return {
                ...state,
                progress: action?.data?.data,
                
            };

        default:
    }

    return state;
}