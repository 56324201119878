// type
import { Home3, Tag2, Colorfilter, Barcode } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Home3,
  typography: Tag2,
  color: Colorfilter,
  shadow: Barcode
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;
let permissionUser = localStorage.getItem('data');

let decryptedData = null;
if(permissionUser){
    try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
}

      const permissions = JSON.parse(decryptedData)?.permissions;




const allowedPermissions = permissions && permissions.length !== 0 ? permissions?.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (batch) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions?.some(permission => permission?.includes(batch));
}

// ==============================|| MENU ITEMS - batch ||============================== //

const batch =
 permissionsAccessFunction('batch') ?
 {
  id: 'utilities',
  title: 'batch',
  icon: icons.Tag2,
  type: 'group',
  children: [
   
    allowedPermissions?.includes('get-batch') ? 
    {
      id: 'all-batchs',
      title: 'All Batch',
      type: 'item',
      url: '/batch/all-batch',
      icon:Tag2,
      breadcrumbs: true
    }
    : null
    ,

    allowedPermissions?.includes('create-batch') ? 
    {
      id: 'create-batchs',
      title: 'Add Batch',
      type: 'item',
      url: '/batch/register-batch',
      icon:Tag2,
      breadcrumbs: true
    }
     : null,

  ].filter(Boolean)
} 
: null;

export default batch;
