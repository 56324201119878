export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const REGISTER_BRAND_OWNER = "REGISTER_BRAND_OWNER";
export const REGISTER_BRAND_USER = "REGISTER_BRAND_USER";
export const REGISTER_CATEGORY_STORE = "REGISTER_CATEGORY_STORE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_STATUS_CODE = "UPDATE_STATUS_CODE";
export const UPDATE_STATUS_USERS = "UPDATE_STATUS_USERS";
export const UPDATE_STATUS_BRAND = "UPDATE_STATUS_BRAND";
export const VIEW_USERS = "VIEW_USERS";
export const VIEW_PRODUCTS = "VIEW_PRODUCTS";
export const VIEW_REPORT_PROBLEM = "VIEW_REPORT_PROBLEM";
export const GET_SERIAL_FOR_BATCH = "GET_SERIAL_FOR_BATCH";
export const VIEW_ACTIVE_PRODUCTS = "VIEW_ACTIVE_PRODUCTS";
export const VIEW_ROLES = "VIEW_ROLES";
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS";
export const UPDATE_RATE_LIMIT = "UPDATE_RATE_LIMIT";
export const GET_BATCH_PRODUCT = "GET_BATCH_PRODUCT";
export const GETALL_PERMISSION = "GETALL_PERMISSION";
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS";
export const EDIT_USER = "EDIT_USER";
export const ALL_BRAND = "ALL_BRAND";
export const MAP_DATA = "MAP_DATA";

export const ALL_FAQS = "ALL_FAQS";
export const ALL_TERMS = "ALL_TERMS";
export const EDIT_COLOR_SHIFT = "EDIT_COLOR_SHIFT";
export const EDIT_SMSREPLY = "EDIT_SMSREPLY";
export const GETALL_CATEGEORY = "GETALL_CATEGEORY";
export const GET_ALL_COLOR_SHIFT = "GET_ALL_COLOR_SHIFT";
export const ALL_SMS_REPLY = "ALL_SMS_REPLY";
export const STATUS_UPDATE_SMSREPLY = "STATUS_UPDATE_SMSREPLY";
export const BRAND_ONE_DETAIL = "BRAND_ONE_DETAIL";
export const BRAND_EDIT = "BRAND_EDIT";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GET_ALL_ACTIVE_COMPANIES = "GET_ALL_ACTIVE_COMPANIES";
export const GET_ALL_ACTIVE_BRANDS = "GET_ALL_ACTIVE_BRANDS";
export const ALL_SUB_CATEGORIES = "ALL_SUB_CATEGORIES";
export const SUB_CATEGORY_SATUS_UPDATE = "SUB_CATEGORY_SATUS_UPDATE";
export const STATUS_UPDATE_CATEGORY = "STATUS_UPDATE_CATEGORY";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const ALL_COMPANY = "ALL_COMPANY";
export const POST_SMS_REPLY = "POST_SMS_REPLY";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const POST_UPDATE_FAQS = "POST_UPDATE_FAQS";
export const POST_UPDATE_TERMS = "POST_UPDATE_TERMS";
export const ALL_BATCHES = "ALL_BATCHES";
export const ALL_BATCHES_COMPANY = "ALL_BATCHES_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const STATUS_UPDATE_COMPANY = "STATUS_UPDATE_COMPANY";
export const COMPANY_DETAIL_ONE_USER = "COMPANY_DETAIL_ONE_USER";
export const All_COUNTS = "All_COUNTS";
// export const All_COUNTS = 'GET_ALL_COUNTS';
export const All_RANDOM_CODE = "GET_ALL_RANDOM_CODE";
export const REQUEST_RANDOM_CODE = "REQUEST_RANDOM_CODE";

export const ALL_SMS_LOGS = "GET_ALL_SMS_LOGS";
export const GET_CSV = "GET_CSV";

export const ALL_SMS_REPORT = "GET_ALL_SMS_REPORT";
export const ALL_QRCODE_REPORT = "GET_ALL_QRCODE_REPORT";
export const EXPORT_CSV = "EXPORT_CSV";
export const EXPORT_QR_CSV = "EXPORT_QR_CSV";
export const EXPORT_CSV_COMPANY = "EXPORT_CSV_COMPANY";
export const EXPORT_CSV_REPORT_PROBLEM = "EXPORT_CSV_REPORT_PROBLEM";
export const EXPORT_CSV_CATEGORYIES = "EXPORT_CSV_CATEGORYIES";
export const EXPORT_CSV_RANDOM_CODE = "EXPORT_CSV_RANDOM_CODE";
export const PROGRESSIVE_CODES = "PROGRESSIVE_CODES";
export const VERIFICATION_LOGINN = "VERIFICATION_LOGINN";
export const PER_POST_ID = "PER_POST_ID";
