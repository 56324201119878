// type
import { Home3, HomeTrendUp } from "iconsax-react";

// icons
const icons = {
  navigation: Home3,
  dashboard: HomeTrendUp,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const ChangePasswordd = {
  id: "group-dashboard",
  // title: 'Navigation',
  icon: icons.navigation,
  type: "group",
  children: [
    {
      id: "chnagffffePassword",
      title: "Change Password",
      type: "item",
      url: "/change-Password",
      icon: icons.dashboard,
      breadcrumbs: false,
    },
  ],
};

export default ChangePasswordd;
