import {

    ALL_SMS_REPLY
} from "../constant/ActionType";

export default function AllSmsReply(state = {
    ViewSmsReply: [],
    lastPage: 1,
}, action) {
    switch (action.type) {

 case ALL_SMS_REPLY:
            return {
                ...state,
                ViewSmsReply: action?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}