
import {
    ALL_BATCHES, GET_SERIAL_FOR_BATCH, GET_BATCH_PRODUCT, ALL_BATCHES_COMPANY
} from "../constant/ActionType";

export default function AllBatchesReducers(state = {
    allbatches: [],
    lastPage:1,
    batchbyproduct:[],
    batchbycompany:[],
    productSerial:null
}, action) {
    switch (action.type) {
        case ALL_BATCHES:
            return {
                ...state,
                allbatches: action?.data?.data?.data,
                lastPage: action?.data?.data?.last_page
            };
        case GET_SERIAL_FOR_BATCH:
            return {
                ...state,
                available_serial_no: action?.data?.data?.available_sr,
                msg: action?.data?.data?.msg,
            };
        case GET_BATCH_PRODUCT:
            return {
                ...state,
                batchbyproduct: action?.data?.data
            };
            case ALL_BATCHES_COMPANY:
                return {
                    ...state,
                    batchbycompany: action?.data
                };

            

        default:
    }

    return state;
}